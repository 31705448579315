import { ButtonContentElementProps } from "./buttonContentElement";

interface ButtonContentElementStyleScopedProps
  extends ButtonContentElementProps {
  scopedSelector: string;
}

/**
 * This is the scoped style from a single CE instance
 * Here you can explicitly style one CE
 *
 */
export default function ButtonContentElementStyleScoped(
  props: ButtonContentElementStyleScopedProps
) {
  return (
    <style jsx global>{`
      :global(.${props.scopedSelector}) {
      }
    `}</style>
  );
}
