import PbContentButton from "@/components/content/pbContentButton/pbContentButton";
import ButtonContentElementStyleScoped from "@/components/contentelements/buttonContentElement/ButtonContentElementStyleScoped";
import useScopedClassName from "@/hooks/useScopedClassName";
import useStrButton from "@/hooks/useStrButton";
import { ContentElementButtonStoreSetting } from "@/types/ceSettings/ceSettings";
import { CEButton } from "@/types/content-elements";
import { getBootstrapAlignmentRowClass } from "@/utils/util";
import clsx from "clsx";
import React from "react";
import ContentElementWrapper from "../wrapper/contentElementWrapper";
import ButtonContentElementStyleGlobal from "./buttonContentElementStyleGlobal";

export interface ButtonContentElementProps {
  content: CEButton;
  position: number;
  ceSettings?: ContentElementButtonStoreSetting;
  isLastElement: boolean;
  isMobile: boolean;
  isFirstElement: boolean;
}

export const BUTTON_CE_NAME = "buttonCE";

export default React.memo(function ButtonContentElement(
  props: ButtonContentElementProps
) {
  const BUTTON_SCOPED_CLASS = useScopedClassName(BUTTON_CE_NAME, props.content, props.position);
  const { buttonClassName } = useStrButton(props.content.cfgStrButton, props.ceSettings?.buttons);

  return (
    <>
      <ContentElementWrapper
        name={BUTTON_CE_NAME}
        scopedClassName={BUTTON_SCOPED_CLASS}
        content={props.content}
        ceSettings={props.ceSettings}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        <div
          className={clsx(
            BUTTON_CE_NAME,
            BUTTON_SCOPED_CLASS,
            getBootstrapAlignmentRowClass(props.content.cfgPositionSelect)
          )}
        >
          <PbContentButton
            url={props.content.btnUrl}
            text={props.content.btnText}
            className={buttonClassName}
            popoverPlacement={props.position < 1 ? "right" : "top"}
            cmsPosition={props.position}
            cmsFieldText="btnText"
            cmsPopoverOpenerId={`${props.position}.btn`}
            getParams={props.content.btnGetParams}
            hrefLang={props.content.btnHrefLang}
            rel={props.content.btnRel}
            target={props.content.btnTarget}
            anchorName={props.content.btnAnchorName}
            cmsFieldGetParams="btnGetParams"
            cmsFieldRel="btnRel"
            cmsFieldTarget="btnTarget"
            cmsFieldHrefLang="btnHrefLang"
            cmsFieldUrl="btnUrl"
            cmsFieldAnchorName="btnAnchorName"
          ></PbContentButton>
        </div>
      </ContentElementWrapper>
      <ButtonContentElementStyleGlobal {...props} />
      <ButtonContentElementStyleScoped
        {...props}
        scopedSelector={BUTTON_SCOPED_CLASS}
      />
    </>
  );
});
